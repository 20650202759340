function Index() {
  return (
    <div className=" bg-[#428EFF] flex flex-1 flex-col md:flex-row items-center justify-center mx-2 sm:mx-4 sm:mr-4 lg:mr-10 rounded-xl shadow-md px-3 lg:px-24 py-2 lg:py-8 mt-8 lg:mt-0">
      <div className="flex flex-col items-center justify-center">
        <div className="m-2 text-xl font-bold text-center text-white lg:text-3xl lg:m-auto font-open">
          Schedule Coming Soon!
        </div>
        <div className="mx-6 my-2 text-lg font-normal text-center text-white lg:my-8 lg:mx-0 font-open lg:text-2xl">
          Discover the Latest Cloud Innovations at CCD Pune - Explore the Exciting
          Schedule and Register Today!
        </div>
        {/* <Link to="/schedule" onClick={ScrollToTop}>
          <button className="px-5 mt-6 mb-3 mx-0 lg:mt-0 py-2 text-theme-blue-color !flex bg-white hover:bg-slate-100 rounded-lg lg:rounded-xl justify-center items-center">
            Schedule is live!
          </button>
        </Link> */}
      </div>
    </div>
  )
}

export default Index
