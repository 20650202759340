import EventItem from '../../components/EventItem'
import { useEffect, useState } from 'react'
import speakersData from '../../assets/data/SpeakerData.json'
import './index.css'

const Index = () => {
  const [speakersDetails, setSpeakersDetails] = useState([])

  function convertTo24HourFormat(time) {
    const [timeValue, amPm] = time.split(' ')
    const [hours, minutes] = timeValue.split(':')

    let convertedHours = parseInt(hours)
    if (amPm === 'PM' && convertedHours !== 12) {
      convertedHours += 12
    } else if (amPm === 'AM' && convertedHours === 12) {
      convertedHours = 0
    }

    const formattedHours = String(convertedHours).padStart(2, '0')
    return `${formattedHours}:${minutes}`
  }

  useEffect(() => {
    setSpeakersDetails(speakersData)
  }, [])
  console.log(speakersDetails)
  return (
    <>
      <div className="flex flex-col items-center justify-center gap-5 px-5 pt-10 mb-5">
        <div className="text-3xl font-extrabold lg:text-5xl ">Talks</div>
        <div className="max-w-4xl mt-2 text-lg font-medium text-center w-fit text-g-gray-8">
          Cloud Community Day Pune 2024 is a power-packed one-day event featuring
          insightful keynotes, technical sessions, and interactive workshops by
          industry experts, designed to empower attendees with the latest trends and
          advancements in cloud computing.
        </div>
      </div>

      <p className="mt-8 ml-8 text-xl">All Talks</p>

      <div className="grid grid-flow-col gap-4 mx-6 overflow-x-auto auto-cols-min snap-x container-snap">
        {speakersDetails
          .sort((a, b) => {
            const timeA = convertTo24HourFormat(a.time)
            const timeB = convertTo24HourFormat(b.time)
            return timeA.localeCompare(timeB)
          })
          .map((speaker, id) => (
            <div
              className="flex flex-col-reverse flex-none snap-start my-2 h-auto bg-slate-100 text-black w-[75vw] sm:w-[45vw] md:w-[40vw] lg:w-[24vw] !mx-auto p-6 rounded-xl mt-3 shadow-md"
              key={id}
            >
              <EventItem speaker={speaker} />
            </div>
          ))}
      </div>
      <p className="mt-8 ml-8 text-xl">Related to Kubernetes</p>
      <div className="grid grid-flow-col gap-4 mx-6 overflow-x-auto auto-cols-min snap-x container-snap">
        {speakersDetails
          .sort((a, b) => {
            const timeA = convertTo24HourFormat(a.time)
            const timeB = convertTo24HourFormat(b.time)
            return timeA.localeCompare(timeB)
          })
          .filter((speaker, id) => speaker.categories.includes('Kubernetes'))
          .map((speaker, id) => (
            <div className="flex flex-col-reverse flex-none snap-start my-2 h-auto bg-slate-100 text-black w-[75vw] sm:w-[45vw] md:w-[40vw] lg:w-[24vw] !mx-auto p-6 rounded-xl mt-3 shadow-md">
              <EventItem speaker={speaker} key={id} />
            </div>
          ))}
      </div>

      <p className="mt-8 ml-8 text-xl">Related to Security</p>
      <div className="grid grid-flow-col gap-4 mx-6 overflow-x-auto auto-cols-min snap-x container-snap">
        {speakersDetails
          .sort((a, b) => {
            const timeA = convertTo24HourFormat(a.time)
            const timeB = convertTo24HourFormat(b.time)
            return timeA.localeCompare(timeB)
          })
          .filter((speaker, id) => speaker.categories.includes('Security'))
          .map((speaker, id) => (
            <div className="flex flex-col-reverse flex-none snap-start my-2 h-auto bg-slate-100 text-black w-[75vw] sm:w-[45vw] md:w-[40vw] lg:w-[24vw] !mx-auto p-6 rounded-xl mt-3 shadow-md">
              <EventItem speaker={speaker} key={id} />
            </div>
          ))}
      </div>

      <p className="mt-8 ml-8 text-xl">Related to Machine Learning</p>
      <div className="grid grid-flow-col gap-4 mx-6 overflow-x-auto auto-cols-min snap-x container-snap">
        {speakersDetails
          .filter((speaker, id) => speaker.categories.includes('Machine Learning'))
          .map((speaker, id) => (
            <div className="flex flex-col-reverse flex-none snap-start my-2 h-auto bg-slate-100 text-black w-[75vw] sm:w-[45vw] md:w-[40vw] lg:w-[24vw] !mx-auto p-6 rounded-xl mt-3 shadow-md">
              <EventItem speaker={speaker} key={id} />
            </div>
          ))}
      </div>

      <p className="mt-8 ml-8 text-xl">Related to Data</p>
      <div className="grid grid-flow-col gap-4 mx-6 overflow-x-auto auto-cols-min snap-x container-snap">
        {speakersDetails
          .filter((speaker, id) => speaker.categories.includes('Data'))
          .map((speaker, id) => (
            <div className="flex flex-col-reverse flex-none snap-start my-2 h-auto bg-slate-100 text-black w-[75vw] sm:w-[45vw] md:w-[40vw] lg:w-[24vw] !mx-auto p-6 rounded-xl mt-3 shadow-md">
              <EventItem speaker={speaker} key={id} />
            </div>
          ))}
      </div>
    </>
  )
}

export default Index
