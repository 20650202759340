import React, { useEffect, useState } from 'react'

function Index() {
  const deadline = 'Sept 21, 2024 08:00:00'
  const [days, setDays] = useState(0)
  const [hours, setHours] = useState(0)
  const [minutes, setMinutes] = useState(0)
  const [seconds, setSeconds] = useState(0)

  const leading0 = (num) => {
    return num < 10 ? '0' + num : num
  }

  const getTimeUntil = (deadline) => {
    const time = Date.parse(deadline) - Date.parse(new Date())
    if (time < 0) {
      setDays(0)
      setHours(0)
      setMinutes(0)
      setSeconds(0)
    } else {
      setDays(Math.floor(time / (1000 * 60 * 60 * 24)))
      setHours(Math.floor((time / (1000 * 60 * 60)) % 24))
      setMinutes(Math.floor((time / 1000 / 60) % 60))
      setSeconds(Math.floor((time / 1000) % 60))
    }
  }

  useEffect(() => {
    setInterval(() => getTimeUntil(deadline), 1000)

    return () => getTimeUntil(deadline)
  }, [deadline])
  return (
    <div className="z-30 flex w-full h-20 mt-4">
      <div className="flex flex-col items-center ml-3">
        <div className="lg:text-3xl font-extrabold rounded-xl border-2 px-5 py-1 text-[#686C71]">
          {leading0(days)}
        </div>
        <div>Days</div>
      </div>
      <div className="flex flex-col items-center ml-3">
        <div className="lg:text-3xl font-extrabold rounded-xl border-2 px-5 py-1 text-[#686C71]">
          {leading0(hours)}
        </div>
        <div>Hours</div>
      </div>
      <div className="flex flex-col items-center ml-3">
        <div className="lg:text-3xl font-extrabold rounded-xl border-2 px-5 py-1 text-[#686C71]">
          {leading0(minutes)}
        </div>
        <div>Minutes</div>
      </div>
      <div className="flex flex-col items-center ml-3">
        <div className="lg:text-3xl font-extrabold rounded-xl border-2 px-5 py-1 text-[#686C71]">
          {leading0(seconds)}
        </div>
        <div>Seconds</div>
      </div>
    </div>
  )
}

export default Index
