import SpeakersComponent from '../../components/Speakers'
const Index = () => {
  return (
    <>
      <SpeakersComponent />
      <div className='my-8 mx-3'>
        <p className='text-center'><a className='text-[color:#4285F4] underline' href='https://ccd2022.gdgcloudpune.com/ccd2022#speaker_grid' target='_blank' rel="noreferrer">Check out</a> speakers of Google Cloud Community Day Pune - 2022</p>
      </div>
    </>
  )
}

export default Index
