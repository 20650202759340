export const titlePartners = [
  {
    name: 'Google Developer Groups Pune  ',
    logo: '/assets/GDG_PUNE.png',
    link: 'https://gdgpune.in/'
  },
  {
    name: 'ML Pune',
    logo: '/assets/ML_Pune.png',
    link: ''
  },
  {
    name: 'Flutter Pune',
    logo: '/assets/flutter.png',
    link: 'https://twitter.com/flutterpune?lang=en'
  },
  {
    name: 'Women Techmakers Pune ',
    logo: '/assets/women.png',
    link: 'https://twitter.com/WTMPune'
  },
  {
    name: 'Google Developer Students Clubs',
    logo: '/assets/gdsc.png',
    link: 'https://wow.gdscpune.tech/'
  },
  {
    name: 'FOSS UNITED',
    logo: '/assets/foss.png',
    link: 'https://twitter.com/punefoss?lang=en'
  },
  {
    name: 'Code Vipassana',
    logo: '/assets/code_vipassna.png',
    link: 'https://twitter.com/punefoss?lang=en'
  }
  // {
  //   name: "Polygon ",
  //   logo: "/assets/polygon.png",
  //   link: "https://www.linkedin.com/company/polygon-guild-pune/?originalSubdomain=in"
  // },
  // {
  //   name: "Dev Code",
  //   logo: "/assets/Dev.png",
  //   link: "https://www.linkedin.com/company/dev-code-community/"
  // }
]
