import React from 'react'

function Index() {
  return (
    <div className="flex flex-col items-center mx-2 mt-6 shadow-md bg-googleYellow md:flex-row lg:mt-0 sm:mx-4 lg:ml-10 lg:mr-5 rounded-xl md:px-10 lg:px-10 lg:py-2 xl:px-32 xl:py-8">
      <div className="flex flex-col items-center justify-center my-2">
        <div className="my-4 text-2xl font-medium text-center text-white lg:m-auto font-open">
          Join the Conversation
        </div>
        <div className="mx-6 text-2xl font-bold text-left text-white lg:my-8 lg:mx-0 font-open">
          #GCCDPune24
        </div>
        <a href="https://twitter.com/intent/tweet?text=%F0%9F%8C%A9%EF%B8%8F%E2%9C%A8+Excited+for+%23CloudCommunityDayPune2024%21+Join+us+to+explore+cloud+trends%2C+gain+valuable+insights%2C+and+connect+with+like-minded+professionals.+Don%27t+miss+this+incredible+learning+journey%21+%F0%9F%8C%A9%EF%B8%8F%F0%9F%9A%80%F0%9F%92%BB+%23GCCDPune2024+%23gdgcloudpune+%23GoogleCloud">
          <button className="w-min py-2 px-8 lg:px-8 mb-3 mt-5 mx-0 lg:mt-0 text-theme-blue-color !flex bg-white hover:bg-slate-100 rounded-lg lg:rounded-xl justify-center items-center">
            <svg
              className="w-3 mr-2 lg:w-5 lg:mr-4"
              viewBox="0 0 44 45"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M39.4955 13.2105C39.5223 13.5985 39.5223 13.9863 39.5223 14.3778C39.5223 26.306 30.4415 40.0628 13.8373 40.0628V40.056C8.93203 40.0628 4.12841 38.6578 0 36.0086C0.713281 36.0946 1.43 36.1375 2.14844 36.1393C6.21401 36.1425 10.1626 34.7789 13.3598 32.2676C11.4761 32.232 9.65053 31.6086 8.13843 30.4846C6.62633 29.3606 5.50327 27.7922 4.92628 25.9986C6.27962 26.2599 7.67387 26.2059 9.00212 25.8429C4.7905 24.9921 1.76069 21.292 1.76069 16.9948V16.8803C3.01617 17.5797 4.42133 17.9673 5.85784 18.0102C1.89062 15.3592 0.668594 10.0823 3.06384 5.95698C5.33015 8.74571 8.15774 11.0265 11.3629 12.6513C14.5681 14.276 18.0793 15.2084 21.6683 15.3878C21.3105 13.8473 21.3635 12.24 21.8218 10.7265C22.2802 9.21291 23.1279 7.84623 24.2801 6.76308C27.9159 3.34517 33.6342 3.52048 37.0518 7.15461C39.0734 6.75542 41.0122 6.0142 42.7845 4.96286C42.1106 7.0531 40.7004 8.82742 38.8162 9.95582C40.6057 9.74484 42.3532 9.26567 44 8.53442C42.7886 10.3486 41.2632 11.9321 39.4955 13.2105Z"
                fill="#1D9BF0"
              />
            </svg>
            <div className="text-sm font-semibold lg:text-lg">Tweet</div>
          </button>
        </a>
      </div>
    </div>
  )
}

export default Index
