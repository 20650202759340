import React from 'react'

function Index() {
  return (
    <div className="grid grid-rows-2 md:grid-rows-1 grid-flow-col gap-4 lg:gap-6 bg-[#EAEAEA] font-black p-6 lg:p-8 text-[#4E4E4E] justify-around mt-4">
      <div className="flex flex-col m-4 text-xl">
        <div className="text-[#428EFF] text-2xl">1</div>
        <div>Complete</div>
        <div>Day</div>
      </div>
      <div className="flex flex-col m-4 text-xl">
        <div className="text-[#FF5145] text-2xl">1000+</div>
        <div>Excited</div>
        <div>Attendees</div>
      </div>
      <div className="flex flex-col m-4 text-xl">
        <div className="text-[#38A852] text-2xl">15+</div>
        <div>Expert</div>
        <div>Speakers</div>
      </div>
      <div className="flex flex-col m-4 text-xl">
        <div className="text-[#FFBB01] text-2xl">3</div>
        <div>Years</div>
        <div>of CCD</div>
      </div>
    </div>
  )
}

export default Index
