import TeamComponent from '../../components/Team'
const Index = () => {
  return (
    <>
      <TeamComponent />
      <div className='my-8 mx-3'>       
      </div>
    </>
  )
}

export default Index
