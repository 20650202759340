function Index({ type, data }) {
  let hoverShadowColor
  if (type === 'title') {
    hoverShadowColor = 'hover:shadow-[#99cafd]'
  } else if (type === 'gold') {
    hoverShadowColor = 'hover:shadow-[#FFCA73]'
  } else if (type === 'silver') {
    hoverShadowColor = 'hover:shadow-[#E0E0DF]'
  } else {
    hoverShadowColor = 'hover:shadow-[#ffe292]'
  }

  return (
    <div
      className={`rounded-xl items-center border border-[#eee] py-2 px-6 my-3 mx-1 transform hover:-translate-y-1 shadow-xl ${hoverShadowColor} transition duration-300 flex `}
    >
      {data.link !== '' ? (
        <a href={data.link} target="_blank" rel="noreferrer">
          <img src={data.logo} className="h-auto cursor-pointer" alt={data.name} />
        </a>
      ) : (
        <img src={data.logo} className="h-auto" alt={data.name} />
      )}
    </div>
  )
}

export default Index
