import React from 'react'

function Index() {
  return (
    <div className="flex flex-col items-center mx-2 my-4 shadow-md bg-googleGreen md:flex-row sm:mx-4 lg:mx-10 lg:my-10 rounded-xl">
      <div className="m-6 lg:m-10">
        <div className="pt-2 pb-4 text-2xl font-semibold text-white md:text-4xl md:pt-6 md:pb-6">
          Hello, CCD'24
        </div>
        <div className="text-justify text-white md:text-2xl">
          We’re bringing you an opportunity to learn about Cloud Technology and
          Google Cloud Certifications from the industry experts at the Cloud
          Community Day India 2024. Our experts will tell you how Google Cloud
          Platform and Google Cloud Certifications can help you in accelerating your
          career by boosting your knowledge. During this event, you’ll also stand a
          chance to win exciting prizes and gain insights on Google Cloud
          Certifications.
        </div>
      </div>
      <iframe
        className="w-[90vw] rounded-xl aspect-video m-4 mx-2 sm:mx-4 lg:mx-10"
        src="https://www.youtube.com/embed/uTg0XAqStaM"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    </div>
  )
}

export default Index
