import React from 'react'
import Tweets from '../Tweets'
import InfoBanner from '../InfoBanner'

function Index() {
  return (
    <div className="flex flex-col xs:flex-col md:flex-row my-4 lg:my-10">
      <Tweets />
      <InfoBanner />
    </div>
  )
}

export default Index
