import { useState, useEffect } from 'react'
import logoNav from '../../assets/Images/nav-logo.png'
import { Link } from 'react-router-dom'

export default function Navbar({ active, handleClick }) {
  const [isOpen, setIsOpen] = useState(false)
  const [scrolled, setScrolled] = useState(false)

  useEffect(() => {
    window.onscroll = function () {
      if (window.scrollY > 50) {
        setScrolled(true)
      } else {
        setScrolled(false)
      }
    }
  }, [])

  const toggleNav = () => setIsOpen(!isOpen)
  const mobileTabClickHandler = (tab) => {
    setIsOpen(false)
    handleClick(tab)
  }

  return (
    <>
      <nav
        className={`${
          scrolled || isOpen ? 'shadow-lg bg-white/90' : ''
        } z-[100] fixed w-full fixed-top top-0`}
      >
        <div className="px-0 mx-2 sm:mx-3 sm:px-4">
          <div className="flex justify-between">
            <div className="flex space-x-7">
              <div>
                <Link to="/" className="flex items-center my-auto">
                  <img src={logoNav} alt="Logo" className="mr-2 w-80" />
                </Link>
              </div>
            </div>

            <div className="items-center hidden md:flex space-x-9">
              <div className="items-center hidden md:flex space-x-9">
                <div
                  role="button"
                  className={
                    active === 'home'
                      ? 'mx-3 py-3 px-2 text-g-blue-3 hover:text-blue-600 border-b-4 border-googleBlue transition duration-300 text-lg'
                      : 'mx-3 py-3 px-2 text-g-gray-7 hover:text-g-gray-9 transition duration-300 text-lg'
                  }
                  onClick={() => handleClick('home')}
                >
                  Home
                </div>
                <div
                  role="button"
                  className={
                    active === 'speakers'
                      ? 'py-3 px-2 text-g-blue-3 hover:text-blue-600 border-b-4 border-googleBlue transition duration-300 text-lg'
                      : 'py-3 px-2 text-g-gray-7 hover:text-g-gray-9 transition duration-300 text-lg'
                  }
                  onClick={() => handleClick('speakers')}
                >
                  Speakers
                </div>
                <div
                  role="button"
                  className={
                    active === 'team'
                      ? 'py-3 px-2 text-g-blue-3 hover:text-blue-600 border-b-4 border-googleBlue transition duration-300 text-lg'
                      : 'py-3 px-2 text-g-gray-7 hover:text-g-gray-9 transition duration-300 text-lg'
                  }
                  onClick={() => handleClick('team')}
                >
                  Team
                </div>
                <div
                  role="button"
                  className={
                    active === 'schedule'
                      ? 'py-3 px-2 text-g-blue-3 hover:text-blue-600 border-b-4 border-googleBlue transition duration-300 text-lg'
                      : 'py-3 px-2 text-g-gray-7 hover:text-g-gray-9 transition duration-300 text-lg'
                  }
                  onClick={() => handleClick('schedule')}
                >
                  Schedule
                </div>
                {/* <div
                  role="button"
                  className={
                    active === 'talks'
                      ? 'py-3 px-2 text-g-blue-3 hover:text-blue-600 border-b-4 border-googleBlue transition duration-300 text-lg'
                      : 'py-3 px-2 text-g-gray-7 hover:text-g-gray-9 transition duration-300 text-lg'
                  }
                  onClick={() => handleClick('talks')}
                >
                  Talks
                </div> */}
                <div
                  role="button"
                  className={
                    active === 'code-of-conduct'
                      ? 'py-3 px-2 text-g-blue-3 hover:text-blue-600 border-b-4 border-googleBlue transition duration-300 text-lg'
                      : 'py-3 px-2 text-g-gray-7 hover:text-g-gray-9 transition duration-300 text-lg'
                  }
                  onClick={() => handleClick('code-of-conduct')}
                >
                  Code of Conduct
                </div>
              </div>
            </div>

            <div className="flex items-center content-center space-x-2 md:hidden ">
              <button
                className="outline-none mobile-menu-button"
                onClick={toggleNav}
              >
                <svg
                  className="w-6 h-6 text-gray-500 hover:text-green-500"
                  x-show="!showMenu"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path d="M4 6h16M4 12h16M4 18h16"></path>
                </svg>
              </button>
            </div>
          </div>
        </div>

        <div className={`${isOpen === false ? 'hidden' : ''} md:hidden`}>
          <ul className="border border-b-2 border-gray-200">
            <li className="active">
              <div
                role="button"
                className="block px-2 py-4 ml-2 text-sm font-semibold text-black"
                onClick={() => mobileTabClickHandler('home')}
              >
                Home
              </div>
              <div
                role="button"
                className="block px-2 py-4 ml-2 text-sm font-semibold text-black"
                onClick={() => mobileTabClickHandler('speakers')}
              >
                Speakers
              </div>
              <div
                role="button"
                className="block px-2 py-4 ml-2 text-sm font-semibold text-black"
                onClick={() => mobileTabClickHandler('team')}
              >
                Team
              </div>
              <div
                role="button"
                className="block px-2 py-4 ml-2 text-sm font-semibold text-black"
                onClick={() => mobileTabClickHandler('schedule')}
              >
                Schedule
              </div>
              {/* <div
                role="button"
                className="block px-2 py-4 ml-2 text-sm font-semibold text-black"
                onClick={() => mobileTabClickHandler('talks')}
              >
                Talks
              </div> */}
              <div
                role="button"
                className="block px-2 py-4 ml-2 text-sm font-semibold text-black"
                onClick={() => mobileTabClickHandler('code-of-conduct')}
              >
                Code of Conduct
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </>
  )
}
