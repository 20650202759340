import ScheduleItem from '../../components/ScheduleItem'
import schedule from '../../assets/data/schedule2024.json'
const Index = () => {
  return (
    <>
      <div className="space-y-5 mb-10 md:mb-20">
        <div className="flex justify-center items-center gap-5 flex-col px-5 pt-10 mb-2">
          <div className="text-3xl lg:text-5xl font-extrabold mb-3">Schedule</div>
          <div className="text-lg max-w-2xl w-fit text-center font-medium text-g-gray-8 mt-2">
            Check out the schedule below and don't forget to mark your calendar so
            that you don't miss out on any sessions.
          </div>
        </div>
        <div className="max-w-7xl mx-auto px-3 md:px-8 lg:px-14 xl:px-20 py-5">
          {schedule.map((elem) => (
            <ScheduleItem
              key={elem.key}
              StartTime={elem.startTime}
              EndTime={elem.endTime}
              SessionName={elem.sessionName}
              SpeakerImg={elem.speakerPic}
              Speaker={elem.speakerName}
              SpeakerUrl={elem.url}
            />
          ))}
        </div>
      </div>
    </>
  )
}

export default Index
