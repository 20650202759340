import { Modal } from 'react-responsive-modal'

const TicketsModal = ({ isOpen, onClose }) => {
  return (
    <Modal
      styles={{
        modal: {
          borderRadius: '10px',
          minWidth: '400px',
          padding: '0',
          margin: '0'
        },
        overlay: {
          minWidth: '100vw'
        }
      }}
      center
      open={isOpen}
      onClose={onClose}
      showCloseIcon
    >
      <div className="w-full h-[750px] md:w-[500px] lg:w-[650px]">
        <iframe
          className="mt-2 iframe"
          src="https://konfhub.com/widget/gccdp24?desc=true&secondaryBg=ffffff&ticketBg=ffffff&borderCl=ffffff&bg=FFFFFF&fontColor=070707&ticketCl=070707&btnColor=4285f4&fontFamily=Prompt&borderRadius=10"
          id="konfhub-widget"
          title="Register for Google Cloud Community Day Pune 2024"
          width="100%"
          height="750"
        ></iframe>
      </div>
    </Modal>
  )
}

export default TicketsModal
