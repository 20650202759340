export const titleSponsors = [
  {
    name: "Google Developers",
    logo: "/assets/developers-social-media.png",
    link: "https://developers.google.com/"
  }
];
export const goldSponsors = [];

export const silverSponsors = [];

export const bronzeSponsors = [];