import { useState } from 'react'

import GoogleCloudLogo from '../../assets/Images/google-cloud-logo.svg'
import CloudCircles from '../../assets/Images/cloud_circles.svg'
import HeroTicket from '../../assets/Images/hero_ticket.svg'
import Counter from '../Counter'
import Stats from '../Stats'
import About from '../About'
import InfoSection from '../InfoSection'

import TicketsModal from '../Modal'

const Hero = () => {
  const [open, setOpen] = useState(false)
  const onOpenModal = () => setOpen(true)
  const onCloseModal = () => setOpen(false)

  return (
    <div className="overflow-hidden">
      <div className="flex flex-col md:flex-row md:mx-10">
        <div className="flex flex-col justify-center items-start m-3 rounded-xl border-[1.5px] border-[#eee] overflow-hidden relative md:pr-4 md:pl-2 w-[90vw] mx-auto md:grow shadow-md">
          <img
            className="mt-3 mx-3 mb-1 w-[60vw] max-w-[20rem]"
            src={GoogleCloudLogo}
            alt="Google Cloud Logo"
          />
          <div className="mx-4 my-6 text-4xl font-black text-theme-blue-color">
            <p className="mb-2">Let's go</p>
            <p className="mb-2">It's Cloud Community</p>
            <p>Days Pune, 2024</p>
          </div>
          <Counter />
          <img
            className="hidden md:block absolute w-1/3 md:w-[5vw] lg:w-1/3 right-0 bottom-0 md:bottom-[94%] lg:bottom-0 z-0 rotate-0 md:rotate-180 lg:rotate-0 "
            src={CloudCircles}
            alt="cloud circles"
          ></img>
        </div>
        <div className="flex flex-col w-[90vw] md:w-[40vw] lg:w-[28rem] mx-auto md:ml-[1rem]">
          <div className="flex flex-col bg-black text-white w-[90vw] md:w-[40vw] lg:w-[28rem] !mx-auto p-6 rounded-xl mt-3 shadow-md">
            <div className="text-4xl">Book your tickets now!</div>
            <div className="mt-4 text-lg">
              Connect with developer communities across Pune for networking, meetups,
              collaboration, and more.
            </div>

            <button
              onClick={onOpenModal}
              className="mt-4 max-w-fit !px-12 !py-2 text-white !flex bg-[#009D43] hover:bg-green-700 rounded-lg !justify-center items-center text-sm"
            >
              Register Now
            </button>

            {/* <button className="mt-4 max-w-fit !px-12 !py-2 text-white !flex bg-[#6fbf91] rounded-lg !justify-center items-center text-sm">
              Coming Soon!
            </button> */}
          </div>
          <a
            href="https://maps.app.goo.gl/CYcuYsvioswBj7Fs7"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="w-[90vw] md:w-[40vw] lg:w-[28rem] mx-auto my-6 md:my-3"
              src={HeroTicket}
              alt="hero ticket"
            />
          </a>
        </div>
      </div>
      <Stats />
      <About />
      <InfoSection />
      <TicketsModal isOpen={open} onClose={onCloseModal} />
    </div>
  )
}

export default Hero
