import { Routes, Route, useNavigate, useLocation } from 'react-router-dom'
import NotFound from './NotFound'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { useEffect, useState } from 'react'
import Home from './Home'
import COC from './COC'
import Speakers from './Speakers'
import Team from './Team'
import Schedule from './Schedule'
import Talks from './Talks'

export default function Navigation() {
  const location = useLocation()
  const getTab = (location) => {
    return location.pathname === '/'
      ? 'home'
      : location.pathname === '/registration'
      ? 'registration'
      : location.pathname === '/speakers'
      ? 'speakers'
      : location.pathname === '/team'
      ? 'team'
      : location.pathname === '/schedule'
      ? 'schedule'
      : location.pathname === '/talks'
      ? 'talks'
      : location.pathname === '/code-of-conduct'
      ? 'code-of-conduct'
      : ''
  }
  const [activeTab, setActiveTab] = useState(getTab(location))
  useEffect(() => {
    const tab = getTab(location)
    setActiveTab(tab)
  }, [location])
  const navigate = useNavigate()

  const navBarTabClickHandler = (tab) => {
    if (tab === 'home') {
      setActiveTab('home')
      if (location.pathname === '/') {
        window.scrollTo(0, 0)
      } else {
        navigate('/')
      }
    }
    if (tab === 'speakers') {
      setActiveTab('speaker')
      navigate('/speakers')
      window.scrollTo(0, 0)
    }
    if (tab === 'schedule') {
      setActiveTab('schedule')
      navigate('/schedule')
      window.scrollTo(0, 0)
    }
    if (tab === 'team') {
      setActiveTab('team')
      navigate('/team')
      window.scrollTo(0, 0)
    }
    if (tab === 'code-of-conduct') {
      setActiveTab('code-of-conduct')
      navigate('/code-of-conduct')
      window.scrollTo(0, 0)
    }
    if (tab === 'talks') {
      setActiveTab('talks')
      navigate('/talks')
      window.scrollTo(0, 0)
    }
  }
  return (
    <>
      <Navbar active={activeTab} handleClick={navBarTabClickHandler} />
      <div className="mt-16">
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/code-of-conduct" element={<COC />} />
          <Route path="/speakers" element={<Speakers />} />
          <Route path="/team" element={<Team />} />
          <Route path="/schedule" element={<Schedule />} />
          <Route path="/talks" element={<Talks />} />
        </Routes>
      </div>
      <Footer />
    </>
  )
}
