import SponsorItem from '../../smallComponents/SponsorItem'
import { titleSponsors } from '../../data/sponsors'

const Sponsors = () => {
  return (
    <div className="mx-3 mt-12 mb-2  xl:mx-10">
      <section>
        <h1 className="mb-3 text-3xl font-bold text-center text-grey-secondary">
          Sponsors
        </h1>
        <p className="mt-2 mb-4 font-light text-center text-gray-500 lg:mb-16 sm:text-xl">
          We're thankful to all our sponsors who are making CCD 2024 Pune amazing.{' '}
          <br />
          To become a sponsor, please email us at{' '}
          <a
            href="mailto:contact@gdgcloudpune.com"
            className="text-theme-blue-color"
          >
            contact@gdgcloudpune.com
          </a>
        </p>
        <div className="flex flex-col items-center justify-center gap-3 pb-14">
          <p className="mt-4 text-lg font-semibold text-theme-blue-color lg:text-2xl ">
            Title Sponsor{titleSponsors.length > 1 ? `s` : ``}
          </p>

          <div
            className={`grid ${
              titleSponsors.length > 1
                ? `grid-cols-2`
                : `grid-cols-${titleSponsors.length}`
            } ${
              titleSponsors.length > 2
                ? `md:grid-cols-3`
                : `md:grid-cols-${titleSponsors.length}`
            } ${
              titleSponsors.length > 3
                ? `lg:grid-cols-4`
                : `lg:grid-cols-${titleSponsors.length}`
            } w-[60vw] sm:w-[50vw] md:w-[44vw] px-2 sm:px-16 md:px-28 lg:px-36 xl:px-52`}
          >
            {titleSponsors.map((data, i) => (
              <SponsorItem key={i} type="title" data={data} />
            ))}
          </div>
        </div>
        {/* <div className="flex flex-col items-center justify-center gap-3 pb-14">
          <p className="text-[#FFCA73] lg:text-2xl font-semibold text-lg mt-4 ">
            Gold Sponsors
          </p>

          <div className="grid grid-cols-2 px-2 md:grid-cols-3 lg:grid-cols-4 sm:px-16 md:px-28 lg:px-36 xl:px-52">
            {
              goldSponsors.map((data, i)=><SponsorItem key={i} type="gold" data={data} />)
            }
          </div>
        </div>

        <div className="flex flex-col items-center justify-center gap-3 pb-14">
          <p className="text-[#E0E0DF] lg:text-2xl font-semibold text-lg mt-4 ">
            Silver Sponsors
          </p>

          <div className="grid grid-cols-2 px-2 md:grid-cols-3 lg:grid-cols-4 sm:px-16 md:px-28 lg:px-36 xl:px-52">
            {
              silverSponsors.map((data, i)=><SponsorItem key={i} type="silver" data={data} />)
            }
          </div>
        </div>

        <div className="flex flex-col items-center justify-center gap-3 pb-14">
          <p className="text-[#8a795d] lg:text-2xl font-semibold text-lg mt-4 ">
            Bronze Sponsors
          </p>

          <div className="grid grid-cols-2 px-2 md:grid-cols-3 lg:grid-cols-4 sm:px-16 md:px-28 lg:px-36 xl:px-52">
            {
              bronzeSponsors.map((data, i)=><SponsorItem key={i} type="bronze" data={data} />)
            }
          </div>
        </div> */}
      </section>

      <br />
    </div>
  )
}

export default Sponsors
