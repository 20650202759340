import Coc from '../../components/COC'
const Index = () => {
  return (
    <>
      <Coc />
    </>
  )
}

export default Index
