function Index({speaker}) {
  console.log(speaker)
  return (
    <>
      <div class="grid grid-cols-8 h-10 mt-4">
        <img
          className="self-center h-7 w-7 lg:h-auto lg:w-auto rounded-full ring-2 ring-white"
          src={speaker.profilePicture}
          alt={speaker.fullName}
        />
        <span class="col-span-5 text-base ml-2 self-center">{speaker.fullName}</span>
        <span class="text-[0.65rem] md:text-xs lg:text-[0.65rem] xl:text-sm ml-2 self-center col-start-7 col-span-2">
          {speaker.time}
        </span>
      </div>
      <div className="text-xl mt-4">
        {speaker.sessions[0].name.length>50?speaker.sessions[0].name.substring(0, 50)+"...":speaker.sessions[0].name}
        {console.log(speaker.sessions[0])}
      </div>
    </>
  )
}

export default Index
