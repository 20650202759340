import CommunityPartners from '../../components/CommunityPartners/communityPartners'
import Faq from '../../components/Faq'
import Hero from '../../components/Hero'
import Sponsors from '../../components/Sponsors'
const HomePage = () => {
  return (
    <>
      <Hero />
      <Sponsors />
      <CommunityPartners/>
      <Faq />
    </>
  )
}

export default HomePage
