import React from 'react'
import SponsorItem from '../../smallComponents/SponsorItem'
import { titlePartners } from '../../data/partners'
function CommunityPartners() {
  return (
    <>
      <div className="mx-3 mt-2 mb-5  xl:mx-10">
        <section>
          <h1 className="mb-3 text-3xl font-bold text-center text-grey-secondary">
            Community Partners
          </h1>
          <p className="mt-2 mb-4 font-light text-center text-gray-500 lg:mb-16 sm:text-xl md:mx-16 lg:mx-60 xl:mx-72">
            We're incredibly grateful to all our community partners whose invaluable
            support is making CCD 2024 Pune an extraordinary event. <br />
          </p>
          <div className="flex flex-col items-center justify-center gap-3 pb-14">
            <div
              className={`grid ${
                titlePartners.length > 1
                  ? `grid-cols-2`
                  : `grid-cols-${titlePartners.length}`
              } ${
                titlePartners.length > 2
                  ? `md:grid-cols-3`
                  : `md:grid-cols-${titlePartners.length}`
              } ${
                titlePartners.length > 3
                  ? `lg:grid-cols-4`
                  : `lg:grid-cols-${titlePartners.length}`
              } w-[90vw] sm:w-[78vw] md:w-[95vw] xl:w-[98vw] px-2 sm:px-16 md:px-28 lg:px-36 xl:px-52`}
            >
              {titlePartners.map((data, i) => (
                <SponsorItem key={i} type="partners" data={data} />
              ))}
            </div>
          </div>
        </section>

        <br />
      </div>
    </>
  )
}

export default CommunityPartners
