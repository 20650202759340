import React, { useState } from 'react'

function Index({
  StartTime,
  EndTime,
  SessionName,
  Speaker,
  SpeakerImg,
  SpeakerUrl
}) {
  const [Shadow, setShadow] = useState(false)
  function MouseOver() {
    setShadow(true)
  }
  function MouseOut() {
    setShadow(false)
  }
  console.log(SpeakerUrl)
  return (
    <div
      className="flex w-full overflow-hidden space-x-2 md:space-x-4 py-1 md:py-3 px-1 md:px-3"
      onMouseOver={MouseOver}
      onMouseOut={MouseOut}
    >
      <div
        className={`flex-0 bg-slate-100 border-[1px] border-[#eee] rounded-lg md:rounded-2xl items-end flex flex-col font-bold justify-center space-y-1 pl-6 md:pl-12 pr-2 md:pr-4 py-3 max-w-md ${
          Shadow ? 'md:shadow-md' : 'md:shadow-none'
        }`}
      >
        <p className="text-xs md:text-xl font-bold">{StartTime}</p>
        <p className="text-xs md:text-xl font-bold">{EndTime}</p>
      </div>
      <div
        className={`flex-1 flex flex-col items-start justify-center px-2 md:px-5 py-2 rounded-lg md:rounded-2xl bg-slate-100 border-[1px] border-[#eee] ${
          Shadow ? 'md:shadow-md' : 'md:shadow-none'
        }`}
      >
        <h2
          className={`${
            SpeakerImg === '' ? 'text-center w-full' : ''
          } text-sm sm:text-xl md:text-2xl `}
        >
          {SessionName}
        </h2>
        {SpeakerImg !== '' ? (
          <a href={SpeakerUrl}>
            <div className="flex items-center justify-center mt-1 md:mt-2 cursor-pointer">
              <div className="flex flex-row rounded-full w-4 h-4 sm:w-6 sm:h-6 md:w-8 md:h-8 border overflow-hidden">
                <img
                  src={SpeakerImg}
                  alt="speaker"
                  className="object-cover w-full h-full"
                />
              </div>
              <p className="font-bold text-xs sm:text-md md:text-lg pl-2">
                {Speaker}
              </p>
            </div>
          </a>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

export default Index
