import { useEffect, useState } from 'react'
import volunteerData from '../../assets/data/volunteerData2024.json'
import placeholder from '../../assets/Images/placeholder1.png'

const Index = () => {
    const [organizers, setOrganizers] = useState([])
    const [volunteers, setVolunteers] = useState([])

    useEffect(() => {
        // Filter the organizers and volunteers
        const organizerList = volunteerData.filter((volunteer) => volunteer.isOrganizer)
        const volunteerList = volunteerData.filter((volunteer) => !volunteer.isOrganizer)

        setOrganizers(organizerList)
        setVolunteers(volunteerList)
    }, [])

    return (
        <>
            {/* Team Introduction */}
            <div className="flex justify-center items-center gap-5 flex-col px-5 pt-10 mb-5">
                <div className="text-3xl lg:text-5xl font-extrabold ">Meet our Team</div>
                <div className="text-lg max-w-2xl w-fit text-center font-medium text-g-gray-8 mt-2">
                    Get to know the talented individuals behind our work.
                    Our team is made up of passionate and skilled people,
                    dedicated to driving innovation and achieving excellence together.
                </div>
            </div>

            {/* Organizers Section */}
            <div className="text-3xl lg:text-4xl font-bold text-center mt-10">Organizers</div>
            <div
                className="grid 
    sm:grid-cols-1 md:grid-cols-2 grid-flow-row place-items-center lg:grid-cols-5 gap-4 max-w-7xl mx-auto px-6 md:px-8 lg:px-14 xl:px-20 py-5"
                style={{ gridAutoRows: '1fr' }}
                id="organizers-grid"
            >
                {organizers.map((organizer, id) => (
                    <div
                        key={id}
                        className="flex flex-col items-center p-4"
                    >
                        <img
                            className="h-28 w-28 rounded-full ring-2 ring-white mb-4 object-cover"
                            src={organizer.image || placeholder}
                            alt={organizer.fullName}
                        />
                        <div className="text-lg font-medium text-center">
                            {organizer.fullName}
                        </div>
                        {/* <div className="text-sm font-light text-center mt-1">
                            {organizer.tagLine}
                        </div> */}
                    </div>
                ))}
            </div>



            {/* Volunteers Section */}
            {volunteers.length > 0 && (
                <>
                    <div className="text-2xl lg:text-3xl font-bold text-center mt-10">Volunteers</div>
                    <div
                        className="grid 
                        sm:grid-cols-1 md:grid-cols-2 grid-flow-row place-items-center lg:grid-cols-4 gap-4 max-w-7xl mx-auto px-6 md:px-8 lg:px-14 xl:px-20 py-5"
                        style={{ gridAutoRows: '1fr' }}
                        id="volunteers-grid"
                    >
                        {volunteers.map((volunteer, id) => (
                            <div
                                key={id}
                                className="flex flex-col items-center p-4">
                                <img
                                    className="h-32 w-32 rounded-full ring-2 ring-white object-cover"
                                    src={volunteer.image || placeholder}
                                    alt={volunteer.fullName}
                                />
                                <div className="text-lg font-medium mt-4 text-center">
                                    {volunteer.fullName}
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </>
    )
}

export default Index
